document.addEventListener('DOMContentLoaded', () => {
    const cells = document.querySelectorAll('.cell');
    const winningCombos = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];
    let currentPlayer = 'X';
    let gameOver = false;

    function init() {
        cells.forEach(cell => {
            cell.textContent = '';
            cell.classList.remove('X', 'O');
            cell.addEventListener('click', cellClick, { once: true });
        });
        currentPlayer = 'X';
        gameOver = false;
        resetMessages();
    }

    function resetMessages() {
        document.querySelector('.win').style.display = 'none';
        document.querySelector('.lost').style.display = 'none';
        document.querySelector('.empate').style.display = 'none';
    }

    function cellClick(e) {
        if (gameOver) return;

        const cell = e.target;
        cell.textContent = currentPlayer;
        cell.classList.add(currentPlayer);

        if (checkWin(currentPlayer)) {
            showEndMessage(currentPlayer === 'X' ? 'win' : 'lost');
            gameOver = true;
        } else if (isDraw()) {
            showEndMessage('empate');
            gameOver = true;
        } else {
            currentPlayer = currentPlayer === 'X' ? 'O' : 'X';
            if (currentPlayer === 'O') {
                setTimeout(machineMove, 500);
            }
        }
    }

    function machineMove() {
        const emptyCells = Array.from(cells).filter(cell => !cell.textContent);
        if (emptyCells.length === 0) return;

        let move;
        for (let combo of winningCombos) {
            let userMoves = combo.filter(index => cells[index].textContent === 'X');
            let emptyMoves = combo.filter(index => !cells[index].textContent);
            if (userMoves.length === 2 && emptyMoves.length === 1) {
                move = cells[emptyMoves[0]];
                break;
            }
        }
        if (!move) {
            move = emptyCells[Math.floor(Math.random() * emptyCells.length)];
        }
        move.click();
    }

    function checkWin(player) {
        return winningCombos.some(combination => {
            return combination.every(index => cells[index].classList.contains(player));
        });
    }

    function isDraw() {
        return [...cells].every(cell => cell.textContent);
    }

    function showEndMessage(result) {
        const messageDiv = document.querySelector(`.${result}`);
        messageDiv.style.display = 'flex';

        // Redireccionar después de 3 segundos
        setTimeout(() => {
            window.location.href = 'home.html';
        }, 2000);

        gameOver = true;
    }

    init();
});



//////Init Animation/////////////////////////
document.addEventListener('DOMContentLoaded', function() {
    const X1 = document.querySelector("g#X1");
    const X2 = document.querySelector("g#X2");
    const X3 = document.querySelector("g#X3");
    const X4 = document.querySelector("g#X4");
    const X5 = document.querySelector("g#X5");

    const O1 = document.querySelector("g#O1");
    const O2 = document.querySelector("g#O2");
    const O3 = document.querySelector("g#O3");
    const O4 = document.querySelector("g#O4");
  
    const X1Time = gsap.timeline();


    X1.style.opacity = 0
    O2.style.opacity = 0
    X2.style.opacity = 0
    O1.style.opacity = 0
    
    X4.style.opacity = 0
    O3.style.opacity = 0
    X3.style.opacity = 0
    O4.style.opacity = 0
    X5.style.opacity = 0
    Line.style.opacity = 0


    X1Time
  
      .to(X1, { opacity: 1, duration: 0.25, delay: 1 })  
      .to(O2, { opacity: 1, duration: 0.25 })
      .to(X2, { opacity: 1, duration: 0.25 })
      .to(O1, { opacity: 1, duration: 0.25 })
      .to(X4, { opacity: 1, duration: 0.25 })
      .to(O3, { opacity: 1, duration: 0.25 })
      .to(X3, { opacity: 1, duration: 0.25 })
      .to(O4, { opacity: 1, duration: 0.25 })
      .to(X5, { opacity: 1, duration: 0.25 })
      .set(Line, { opacity: 1, scaleX: 0, scaleY: 0 })
      .to(Line, { opacity: 1, scaleX: 1, scaleY: 1, duration: 0.5 });
  });



  ///////button hide////////////////
  document.getElementById('hideButton').addEventListener('click', function() {
    document.getElementById('sectionToHide').style.display = 'none';
});



